<template>
  <div>
    <table class="table table-report -mt-2">
      <thead>
        <tr>
          <th class="whitespace-nowrap">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </th>
          <th class="whitespace-nowrap">
            INVOICE
          </th>
          <th class="whitespace-nowrap">
            BUYER NAME
          </th>
          <th class="text-center whitespace-nowrap">
            STATUS
          </th>
          <th class="whitespace-nowrap">
            PAYMENT
          </th>
          <th class="text-right whitespace-nowrap">
            <div class="pr-16">
              TOTAL TRANSACTION
            </div>
          </th>
          <th class="text-center whitespace-nowrap">
            ACTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-27807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Charlize Theron</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $27,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-95807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Will Smith</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              California, LA
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-danger"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Inactive
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Checking payments
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              30 March, 11:00
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $95,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-115807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Leonardo DiCaprio</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $115,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-142807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Brad Pitt</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $142,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-60807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Leonardo DiCaprio</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $60,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-32807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Keira Knightley</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $32,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-49807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Richard Gere</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              California, LA
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-danger"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Inactive
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Checking payments
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              30 March, 11:00
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $49,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-47807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Will Smith</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              California, LA
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-danger"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Inactive
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Checking payments
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              30 March, 11:00
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $47,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
        <tr class="intro-x">
          <td class="w-10">
            <input
              class="form-check-input"
              type="checkbox"
            >
          </td>
          <td class="w-40 !py-4">
            <a
              href=""
              class="underline decoration-dotted whitespace-nowrap"
            >#INV-27807556</a>
          </td>
          <td class="w-40">
            <a
              href=""
              class="font-medium whitespace-nowrap"
            >Kevin Spacey</a>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              Ohio, Ohio
            </div>
          </td>
          <td class="text-center">
            <div
              class="flex items-center justify-center whitespace-nowrap text-success"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-2"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
              Active
            </div>
          </td>
          <td>
            <div class="whitespace-nowrap">
              Direct bank transfer
            </div>
            <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">
              25 March, 12:55
            </div>
          </td>
          <td class="w-40 text-right">
            <div class="pr-16">
              $27,000,00
            </div>
          </td>
          <td class="table-report__action">
            <div class="flex justify-center items-center">
              <a
                class="flex items-center text-primary whitespace-nowrap mr-5"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="9 11 12 14 22 4" />
                <path
                  d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                />
              </svg>
                View Details </a><a
                class="flex items-center text-primary whitespace-nowrap"
                href="javascript:;"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide w-4 h-4 mr-1"
              >
                <polyline points="17 11 21 7 17 3" />
                <line
                  x1="21"
                  y1="7"
                  x2="9"
                  y2="7"
                />
                <polyline points="7 21 3 17 7 13" />
                <line
                  x1="15"
                  y1="17"
                  x2="3"
                  y2="17"
                />
              </svg>
                Change Status
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// import HelloWorldVue from "./components/HelloWorld.vue";
export default {
  name: 'App',
  components: {
    // HelloWorld: HelloWorldVue,
  },
}
</script>
